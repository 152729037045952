.Form {
    margin-top: rem-calc(50); }

.Form__title {
    width: 100%;
    padding: rem-calc(5 10);
    margin-bottom: 0;
    background: $primary-color;
    color: $white;
    display: block;
    font-weight: 700;
    text-transform: uppercase; }

.Form__body {
    padding: rem-calc(15);
    border: #{$fieldset-border};
    border-top: none; }

.Form__button {
    padding-top: 1em;
    padding-bottom: 1em; }
