.Quiz {
    .Quiz__answer {
        margin-bottom: rem-calc(10);

        .column:first-of-type {
            line-height: 1.8rem; }

        label {
            font-size: 16px; }

        input[type="radio"] {
            margin: 0; } }

    .help-text {
        margin-bottom: rem-calc(10); }

    .Quiz__reaction {
        .subheader {
            font-weight: bold;
            color: #474F56; } } }
