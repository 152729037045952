.Hero {
    width: 100%;
    height: 480px;
    background-color: #E6E2DF;
    background-image: url(/images/SearchingTheScriptures.jpg);
    background-size: auto 72.708333333333333333333333333333%;
    background-repeat: no-repeat;
    background-position: center center;

    @include breakpoint(small only) {
        max-height: rem-calc(300); } }
