.Header {
    @include flex;
    @include flex-align(left, middle);

    background: #20272F;
    height: 60px;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 1px;

    a {
        color: #404E59; } }
