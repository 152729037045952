.Spreadsheet {
    width: 100%;

    th {
        height: rem-calc(40);
        width: calc(100% / 6); }

    th, td {
        text-align: center;
        border: 1px solid $medium-gray; } }

.Spreadsheet__correct {
    background: $success-color; }
